.document-table {
  .result-table-container {
    @include margin-size(margin-bottom, 35);

    @include mobile(320px, 1023px) {
      overflow-x: scroll;
    }

    div {
      div.table {
        width: 100%;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        border-spacing: 0.56em;

        @include mobile(320px, 429px) {
          width: 300%;
          overflow-x: scroll;
        }
        @include mobile(430px, 767px) {
          width: 250%;
          overflow-x: scroll;
        }
        @include mobile(768px, 1023px) {
          width: 180%;
          overflow-x: scroll;
        }

        div.row.head-row {
          border-bottom: 2px solid #d6dce3;

          div.column {
            p {
              @include rem-size(line-height, 24);
              font-weight: 600;
              margin-bottom: 0.5em;
            }
          }
        }

        div.row {
          border-bottom: 1px solid #d6dce3;
          margin-right: 0px !important;
          margin-left: 0px !important;
          div.column {
            svg {
              height: 17px;
              width: auto;
              fill: $secondary;
              margin: 0px 10px;
            }

            font-size: 0.8rem;

            &.text-align-center {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
