.contenttype-areaprofessionisti {
  .search-box-group {
    display: flex;
    column-gap: 20px;
    align-items: center;

    input {
      border-bottom: none;
      background-color: $primary-a0;
      border-radius: 4px;
      &:focus {
        border: 1px solid;
        border-color: hsl(36deg, 100%, 50%);
        box-shadow: 0 0 0 2px hsl(36deg, 100%, 50%);
        outline: none;
      }
    }
  }

  .export-button-group {
    display: flex;
    align-items: center;
    @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      width: 100%;
      justify-content: space-between;
    }

    .button-item-container {
      background-color: $primary;
      border-radius: 4px;
      padding: 0.35rem 0.75rem;
      color: $white;
      text-decoration: none;
      text-align: center;

      &:hover {
        background-color: darken($primary, 5%);
      }
      &:focus {
        border: 1px solid;
        border-color: hsl(36deg, 100%, 50%);
        box-shadow: 0 0 0 2px hsl(36deg, 100%, 50%);
        outline: none;
      }
    }
  }

  .folderContentsContainer {
    .folderContentsTable {
      .tableRow.tableHeader {
        margin: 0em;
        padding: 0em;
        border-top: 1px solid #a4a7a9;

        button {
          background-color: transparent;
          border: none;

          svg.sort-icon {
            height: 1.1rem;
            fill: #a4a7a9;
          }
        }
      }
      .tableRow {
        font-size: 1rem;

        &:first-child {
          border-top: 1px solid #a4a7a9;
        }
      }
    }
  }
}
.contenttype-areaprofessionisti {
  .search-box-group {
    display: flex;
    column-gap: 20px;
    align-items: center;

    input {
      border-bottom: none;
      background-color: $primary-a0;
      border-radius: 4px;
      &:focus {
        border: 1px solid;
        border-color: hsl(36deg, 100%, 50%);
        box-shadow: 0 0 0 2px hsl(36deg, 100%, 50%);
        outline: none;
      }
    }
  }

  .export-button-group {
    display: flex;
    align-items: center;
    @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      width: 100%;
      justify-content: space-between;
    }

    .button-item-container {
      background-color: $primary;
      border-radius: 4px;
      padding: 0.35rem 0.75rem;
      color: $white;
      text-decoration: none;
      text-align: center;

      &:hover {
        background-color: darken($primary, 5%);
      }
      &:focus {
        border: 1px solid;
        border-color: hsl(36deg, 100%, 50%);
        box-shadow: 0 0 0 2px hsl(36deg, 100%, 50%);
        outline: none;
      }
    }
  }

  .folderContentsContainer {
    .folderContentsTable {
      .tableRow.tableHeader {
        margin: 0em;
        padding: 1em 0;
        border-top: 1px solid #a4a7a9;

        .orderingSelector {
          background-color: transparent;
          border: none;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding: 0;
          font-weight: 700;
          &.ascending {
            align-items: flex-end;
          }
          &.descending {
            align-items: flex-start;
          }

          svg.sort-icon {
            height: 1.1rem;
            fill: #a4a7a9;
          }
        }
      }
      .tableRow {
        font-size: 1rem;

        &:first-child {
          border-top: 1px solid #a4a7a9;
        }
      }
    }
  }
}
