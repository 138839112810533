.alphabet-navbar-section {
  margin-top: 2.1em;

  @include mobile(320px, 1023px) {
    overflow-x: scroll;
  }

  .alphabet-navbar-container {
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    border-bottom: 1px solid #e5e5e5;

    @include mobile(320px, 767px) {
      width: 200%;
    }
    @include mobile(768px, 1023px) {
      width: 150%;
    }

    .navbar-button-container {
      width: 100%;
      .navbar-tab {
        background-color: transparent;
        border: none;
        font-size: 1.1rem;
        line-height: 2rem;
        font-weight: 600;
        width: 100%;
        padding: 0em 0em 0.1em;

        .letter-container {
          padding: 0.3em 0em;
        }
      }

      .navbar-tab:hover,
      .navbar-item-active {
        background-color: rgba(0, 57, 97, 0.07);
        cursor: pointer;
        border-bottom: 2px solid $secondary;
        color: $secondary;
        padding-bottom: 0em;
      }

      button:focus {
        outline: none;
      }
    }
  }
}
