.anagrafica {
  .section-header {
    h5 {
      font-weight: 700;
    }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.25rem;
  }
  h5 {
    font-weight: 300;
  }
  .anagrafica-row {
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    &:last-child {
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
    .anagrafica-row-label {
      flex: 1;
      padding: 0 1.125rem;
      font-weight: 700;
    }
    .anagrafica-row-value {
      flex: 1;
      padding: 0 1.125rem;
    }
  }
}

.referenze-del-fornitore {
  margin-top: 4rem !important;
}

a.add-edit-link {
  text-decoration: none;
  text-transform: uppercase;
  color: $primary-dark;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  &:hover {
    text-decoration: underline;
  }
  .icon {
    fill: $primary-dark;
  }
}
