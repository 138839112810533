.folderContentsContainer {
  .folderContentsTable {
    margin-top: 3rem;
    .tableRow {
      border-top: 1px solid gray;
      padding: 1.75rem 0;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        border-bottom: 1px solid gray;
      }
    }
  }
}
