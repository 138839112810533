@mixin mobile($min-size, $max-size) {
  @media only screen and (min-width: $min-size) and (max-width: $max-size) {
    @content;
  }
}

.servizi-base-container {
  width: 100%;
  background: $primary-a0;
  // background: rgba(0, 57, 97, 0.07);
  display: flex;
  justify-content: center;
  height: fit-content;

  &.full-width {
    @include mobile(320px, 430px) {
      left: calc(50% + 15px);
      width: calc(100vw - 30px) !important;
    }
  }

  .servizi-base-content {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    padding: 1.95em 0em 3.61em;
    width: 100%;

    & .edit-subcontainer {
      margin: 0em auto;
      padding: 0em 19.5em;
    }

    .title-section {
      flex-basis: 100%;
      text-align: center;

      @include mobile(320px, 430px) {
        flex-basis: 80%;
      }

      h4 {
        color: #004372;
        font-size: 1.77rem;
        line-height: 2.66rem;
        margin-bottom: 1.95em;

        @include mobile(320px, 430px) {
          font-size: 1.56rem;
          line-height: 1.95em;
        }

        .public-DraftEditorPlaceholder-root {
          width: 100%;
          .public-DraftEditorPlaceholder-inner {
            text-align: center;
          }
        }
        .public-DraftEditor-content {
          text-align: center;
        }
      }
    }

    .card-container {
      display: flex;
      justify-content: space-evenly;

      @include mobile(320px, 1024px) {
        flex-wrap: wrap;
      }

      .card {
        flex-basis: 23%;
        margin: 0em 0.83em 1.1em;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
        background-color: $white;
        flex: 0 0 25%;
        width: 17.9rem;
        min-width: 17.9rem;
        max-width: 17.9rem;

        @include mobile(320px, 430px) {
          flex: 1 1 auto;
          max-width: 80%;
        }

        @include mobile(430px, 767px) {
          min-width: 11.1rem;
          flex-basis: 43%;
        }

        @include mobile(768px, 1023px) {
          min-width: 11.1rem;
          flex-basis: 40%;
        }

        @include mobile(1024px, 1280px) {
          margin: 0em 1em 2.5em;
          min-width: 11rem;
        }

        .card-title-section {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          padding: 1.1em 0.83em;
          border-bottom: 1px solid #e5e5e5;
          height: 3.8rem;
          display: flex;
          align-items: center;
          column-gap: 0.83em;

          @include mobile(320px, 430px) {
            min-height: 2.2rem;
            padding: 0.55em 0.8em;
          }
          @include mobile(1024px, 1280px) {
            align-items: start;
            min-height: 0.5rem;
            padding: 0.3em 0.8em;
          }

          .card-icon-container {
            align-items: center;
            display: flex;
            svg {
              height: 1.33rem;
              width: auto;
              margin: 0rem;
              @include mobile(1024px, 1280px) {
                height: 1rem;
                width: auto;
              }
            }

            @include mobile(320px, 767px) {
              height: 1.1rem;
            }
          }

          .card-title-container {
            overflow: hidden;

            h5,
            a {
              font-size: 1.33rem;
              line-height: 1;
              margin: 0em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              @include mobile(320px, 767px) {
                font-size: 1.1rem;
              }

              @include mobile(1024px, 1280px) {
                font-size: 1rem;
              }
            }

            a {
              font-family: $font-family-sans-serif;
              text-decoration: none;
              color: $primary;
              font-weight: 400;

              &:hover {
                text-decoration: underline;
              }
            }

            h5.placeholder {
              font-size: 1.1rem;
              line-height: 2rem;
              margin: 0px 0.55em;
              color: opacify($color: $black, $amount: 0.8);
            }
          }
        }

        .card-content-section {
          padding: 1.1em 1.39em;
          font-family: 'Titillium Web';

          @include mobile(1024px, 1280px) {
            padding: 0.5em 0.8em;
          }

          p {
            @include mobile(1024px, 1280px) {
              margin-bottom: 0.3em;
            }
            a {
              color: $secondary;
              font-size: 1rem;
              line-height: 1.56rem;

              @include mobile(1024px, 1280px) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
