.ricerca-moduli {
  .rounded.container {
    background-color: $secondary;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    padding: 1em 1.89em 0.83em;
    margin-bottom: 3em;

    div.d-flex.justify-content-center {
      flex-basis: 100%;
      div.d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
        padding: 0px;
        form.search-form {
          display: flex;
          flex-wrap: wrap;
          width: 100;
          flex-basis: 100%;

          .search-box-container {
            flex-basis: 100% !important;
            align-items: center;
            margin-bottom: 0.61em;
            display: flex;

            .filter-wrapper {
              margin-right: 0em !important;
              input[type='text'] {
                width: 100%;
                font-size: 1rem;
                line-height: 1.67rem;
                color: $body-text-color;
                padding: 0.67em 0em 0.61em 1.3em;

                @include mobile(320px, 411px) {
                  font-size: 1.3rem;
                  padding: 0.56em 0em 0.56em 0.278em;
                }
              }
            }
          }

          .filters-line {
            flex-basis: 100%;
            display: flex;
            align-items: center;

            @include mobile(320px, 1023px) {
              flex-wrap: wrap;
              justify-content: center;
            }

            div.filter-wrapper.select-filter {
              flex-basis: 20%;

              @include mobile(430px, 767px) {
                margin: 0.56em 0.83em;
              }
              @include mobile(768px, 1023px) {
                flex-basis: 40%;
                margin: 0.56em 1.39em;
              }

              div.bootstrap-select-wrapper {
                div {
                  div#struttura,
                  div#tipo-documento,
                  div#area-interesse,
                  div#utile-per {
                    div.react-select__control {
                      div.react-select__value-container {
                        color: $primary-text-color;
                        font-size: 1rem;
                        line-height: 1.167rem;
                        min-height: auto;
                        overflow: visible;

                        @include mobile(320px, 767px) {
                          font-size: 1.1em;
                        }

                        div.react-select__placeholder {
                          font-size: inherit;
                          top: 65%;
                          @include mobile(320px, 767px) {
                            font-size: 1.1em;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            button.apply-filters-button {
              background-color: $tertiary-button-color;
              font-size: 1rem;
              line-height: 1.1rem;
              color: $body-text-color;
              border-radius: 4px;
              width: 11rem;
              height: 2.2rem;
              font-family: 'Titillium Web';
              font-weight: bold;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;

              @include mobile(320px, 767px) {
                margin-top: 1.167em;
                margin-bottom: 1.1em;
                font-size: 1.2rem;
              }
              @include mobile(768px, 1023px) {
                margin-top: 1.1em;
                margin-bottom: 1.1em;
              }
              @include mobile(1024px, 1280px) {
                width: 8.9rem;
              }
            }
          }
        }
      }
    }
  }

  .result-table-section {
    .result-table-container {
      margin-bottom: 2.78em;
      @include mobile(320px, 1023px) {
        overflow-x: scroll;
      }
      table {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        border-spacing: 0.56em;
        width: 100%;

        @include mobile(320px, 429px) {
          width: 500%;
          overflow-x: scroll;
        }
        @include mobile(430px, 767px) {
          width: 300%;
          overflow-x: scroll;
        }
        @include mobile(768px, 1023px) {
          width: 180%;
          overflow-x: scroll;
        }

        thead {
          tr {
            border-bottom: 2px solid #d6dce3;
            th {
              line-height: 40px;

              p {
                @include mobile(1024px, 1280px) {
                  font-size: 0.72rem;
                }
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #d6dce3;
          }
        }

        tr,
        td,
        th {
          font-size: 0.8rem;
          line-height: 1.3rem;
          color: #272727;
          @include mobile(320px, 767px) {
            font-size: 1.3rem;
          }

          p {
            margin: 10px auto;
            @include mobile(320px, 767px) {
              font-size: 1.3rem;
            }
            @include mobile(1024px, 1280px) {
              font-size: 0.78rem;
            }
          }

          .button {
            margin: 0.56em auto;
            background-color: $tertiary;
            border-radius: 4px;
            border: none;
            color: #fff;
            font-size: 0.72rem;
            font-weight: 700;
            line-height: 1.1rem;
            padding: 0.4em 1.3em;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 2rem;

            @include mobile(320px, 767px) {
              font-size: 1.3rem;
              min-height: 3rem;
              padding: 0.8em 0.5em;
            }
          }
        }
      }
    }
  }
}
