.rubrica-link-section {
  display: flex;
  align-items: center;
  border-right: 1px solid $header-slim-text-color;
  padding: 0.5em 0.6em;
  a {
    svg {
      margin-right: 0.28em;
    }
    font-size: 0.8rem;
  }
}
