#terms-and-conditions {
  margin-bottom: 8rem;
  position: relative;
  .termsWrapper {
    height: 550px;
    @media (max-height: 766px) {
      height: calc(100vh - 450px);
    }
    @media (max-height: 567px) {
      height: 250px;
    }
    overflow-y: auto;
    padding: 1.5rem 1rem;
    background-color: #e5e5e5;
    border: 1px solid #575757;
    border-radius: 4px;
  }
  .acceptTermsActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: -6rem;
    right: 1rem;
    width: 100%;
    background-color: #fff;
  }
}
