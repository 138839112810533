.contenttype-referenza {
  h5 {
    font-weight: 600;
  }

  .section-content {
    p {
      font-family: Lora;
    }

    .chip {
      border: 1px solid $tertiary;
      min-width: 3.9rem;

      .chip-label {
        color: $tertiary;
      }
    }

    .content-details {
      display: flex;
      width: 100%;

      .tipologia,
      .importo {
        flex-basis: 50%;
        p {
          font-family: 'Titillium Web';
        }
      }
    }

    .card {
      .card-title {
        h6 {
          font-size: 1.3rem;
        }
      }

      strong {
        font-size: 0.89rem;
      }
      p {
        font-family: 'Titillium Web';
      }

      ul {
        padding-inline-start: 0.8em;

        li::marker {
          font-size: 10px;
          margin-bottom: 2px;
        }
      }

      .card-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.67em;

        .chip {
          margin: 0px;
          border-radius: 5.4px;
          background-color: #848484;
          border: none;
          .chip-label {
            color: #fff;
          }
        }

        strong {
          margin-right: 3px;
        }

        p {
          margin-bottom: 0em;
          font-size: 0.89em;
        }
      }

      &.date-e-scadenze-card::after {
        margin: 0px;
      }
    }
  }
}
