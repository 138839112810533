.argumentInEvidence {
  svg.icon.fa-icon.fal.icon {
    fill: $secondary;
  }

  p {
    font-size: 0.89rem;

    a {
      color: $secondary;
    }

    a:hover {
      color: $primary;
    }
  }

  span.text {
    color: $secondary;
  }
}
