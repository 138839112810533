.page-header-alert.alert {
  background-image: none;
  border: 1px solid #e6e6e8;
  border-left: 4px solid $secondary;
  padding: 0.7em 1.39em;

  .alert-content {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 1.2rem;
    align-items: flex-start;
    .alert-icon {
      display: flex;
      flex-basis: 5%;
      svg {
        fill: $secondary;
        height: 1rem;
      }
    }

    .alert-text {
      font-size: 0.93rem;
      flex-basis: 92%;
    }
  }
}
