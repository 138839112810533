// colore barra progress in pagine CT
.public-ui .link-list-wrapper.menu-link-list h3:after,
.public-ui .link-list-wrapper.menu-link-list h4:after {
  background-color: $secondary;
}

// colore link sideMenu
.public-ui .navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
  &.active {
    span {
      font-weight: 600;
      color: $secondary;
    }
    border-left: none;
  }

  span {
    color: $secondary;
    font-weight: 400;
  }
}

// sfondo blocco elenco
.block.listing {
  .public-ui .full-width.bg-light {
    background-color: $primary-a0 !important;
  }
}

// colore icona pagina Argomento
.contenttype-pagina-argomento {
  .ArgomentoTitleWrapper {
    svg {
      fill: $secondary;
    }
  }
}

// size blocco immagine per responsive
.block.image.align.full {
  img.full-width {
    @include mobile(320px, 1023px) {
      height: 14rem;
    }
  }
}

// Do not overlap if consecutive
.attachments-section + .attachments-section,
.attachments-section + .persona-section,
.persona-section + .attachments-section,
.attachments-section + .persona-section {
  margin-top: 2.5rem;
}

// box allegati, professionisti
.container.box-container.attachments-container,
.container.box-container.persona-container {
  background-color: $primary-a0;
  padding: 1.75rem;

  .section-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .attachments {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-row: auto auto;

    .attachment {
      margin-top: 0 !important;
      .card-body {
        max-width: calc(100% - 3rem);
      }
    }
  }
  .showMore {
    margin-top: 2rem;
    a {
      color: $white;
      text-decoration: none;
    }
  }
  .redHelpText {
    color: red;
    font-weight: 700;
  }
}

// CT associato, legale rappresentante
.ct-persona-anagrafica {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;

  .page-header {
    width: 60%;
  }

  .content-container {
    width: 60%;

    .card {
      width: 60%;
    }
  }
}

#anagrafica .section-header {
  .actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .add-edit-link:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
}

.page-header {
  .albo-actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    div:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
  #anagrafica .section-header {
    h5,
    .actions {
      width: 100%;
      flex-wrap: wrap;
    }
    display: flex;
    flex-direction: column;
  }
  .page-header {
    .albo-actions {
      width: 100%;
      flex-wrap: wrap;
    }
  }
}

body:not(.role-manager) {
  #toolbar {
    .toolbar-actions {
      a[aria-label='Modifica'],
      a[aria-label='Contenuti'],
      button#toolbar-more {
        display: none;
      }
    }
    a#toolbar-customer-satisfaction-panel {
      display: none;
    }
  }
}

.users-control-panel {
  table {
    margin-bottom: 5rem !important;
  }
}
