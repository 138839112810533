#page-edit,
#page-add {
  form .grid {
    margin: 0 !important;
    .three.wide.column {
      display: none;
    }
    .nine.wide.column {
      width: 100% !important;
    }
  }
}
