.side-menu-container {
  position: relative;
  aside {
    z-index: 1;
    .page-side-menu {
      .navbar {
        .menu-wrapper {
          width: 100%;
          .link-list-wrapper.menu-link-list {
            .accordion-wrapper {
              .collapse-div {
                border-bottom: none;
                .collapse-header {
                  width: 100%;
                  display: block;
                  border-bottom: 4px solid $border-color;

                  &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    width: 40px;
                    height: 4px;
                    background-color: $warning;
                  }
                  button.accordion-button {
                    border: none;
                    background: transparent;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    padding: 14px 24px;
                    text-decoration: none;

                    h3 {
                      padding: 0px;
                      border-bottom: none;
                      margin-bottom: 0px;
                      &::after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .department-info {
        background-color: $primary-a0-saturated;
        padding: 1.5em 1em;
        border-left: 2px solid $primary;

        .accordion-wrapper {
          .collapse-div {
            border-bottom: none;
            .collapse-header *[data-toggle='collapse'] {
              border-top: none;
              padding: 0px;

              h5 {
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 0.833rem;
                font-weight: 600;
              }
            }
          }

          .collapse-body {
            padding: 0px;
            margin-top: 1.5em;
            ul {
              list-style-type: none;
              margin: 0px;
              margin-block-start: 0;
              padding-inline-start: 0;
              .dep-info-item {
                margin: 1em 0em;

                a {
                  font-size: 1em;
                  color: $secondary;
                  font-weight: 600;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .page-side-menu {
    .menu-wrapper {
      .accordion-wrapper {
        .accordion {
          .accordion-header {
            button.accordion-button {
              .accordion-title {
                padding-bottom: 1.3em;
                padding-top: 1.3em;
              }
            }
          }
        }
      }
    }
    .navbar.it-navscroll-wrapper {
      .menu-wrapper {
        padding: 0;
      }
    }
  }

  .it-page-sections-container {
    border-top: none !important;
  }
}
