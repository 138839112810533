.specificCategoryField {
  .ui.grid {
    .row {
      flex-direction: column !important;
      overflow-x: auto;
      .column[class*='wide'] {
        width: 100% !important;
        table {
          font-size: 14px;
          thead {
            font-size: 11.5px;
            text-align: center;
          }
          td[class*='specific-category-'],
          th[class*='specific-category-'] {
            width: 75px;
            min-width: 75px;
            padding: 0.5rem;
          }
          td[class*='specific-category-'] {
            text-align: center !important;
          }

          td.specific-category-label {
            width: 120px;
            min-width: 120px;
            text-align: left !important;
            font-weight: 700;
          }
          td.specific-category-value {
            width: 90px;
            min-width: 90px;
            .ui.input {
              width: 90px !important;
            }
          }
        }
      }
    }
  }
}
