.it-header-slim-wrapper {
  background-color: #01375d;
}

.it-header-center-wrapper {
  height: fit-content;
  padding: 12px 0px;

  .it-header-center-content-wrapper {
    margin-left: 1.38em;
    .it-brand-wrapper {
      @media (min-width: map-get($map: $grid-breakpoints, $key: 'xs')) and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
        padding-left: 0em !important;
      }
      @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) and (max-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
        padding-left: 0em !important;
      }

      a {
        .icon {
          height: auto;
          width: 242px;

          img {
            margin: 0px;
          }
        }

        @media (min-width: map-get($map: $grid-breakpoints, $key: 'xs')) and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
          .icon {
            margin-left: 2rem;
            width: 142px;

            img {
              margin: 1rem 0rem;
            }
          }
        }
        @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) and (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
          .icon {
            width: 182px;
            margin-left: 1rem;

            img {
              margin: 1rem 0rem;
            }
          }
        }
      }

      .it-brand-text {
        h2,
        h3 {
          color: $primary;
          font-weight: 700;
        }
        h2 {
          margin-bottom: 8px;

          &:after {
            content: 'Ente ospedaliero';
            display: block;
            font-weight: 400;
            font-size: 0.875em;
          }
        }
        h3 {
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }

    .it-search-wrapper {
      a.rounded-icon {
        background: white;
        box-shadow: 0 2px 8px 0 transparentize($color: #3b3b3b, $amount: 0.76);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        transition: 0.3s ease all;

        svg {
          fill: $primary;
        }

        &:hover {
          background: darken($primary, 5%);

          svg {
            fill: white;
          }
        }
      }
    }
  }
}

button.custom-navbar-toggler svg.icon {
  fill: $primary;
}

.it-footer .it-footer-main {
  background-color: $primary-dark;

  .it-brand-wrapper {
    a {
      .icon {
        width: 350px;
        height: auto;
      }

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'xs')) and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
        .icon {
          width: 300px;
          margin-right: 0px;
        }
      }

      .it-brand-text {
        @media (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
          h2 {
            display: none;
          }
        }

        h3 {
          display: none !important;
        }
      }
    }
  }
}

.it-footer-small-prints {
  background-color: $primary-dark;
}

@media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
  .it-header-navbar-wrapper {
    background-color: $primary;
  }

  .it-header-center-wrapper {
    height: 165px;

    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            width: 350px;

            img {
              width: 350px;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
