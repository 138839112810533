.full-width.specialisti {
  background-color: $primary;
  display: flex;
  justify-content: center;
  height: auto;
  padding: 2.39em 0em;
  .specialisti-block-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile(320px, 599px) {
      width: 80%;
    }
    @include mobile(600px, 1023px) {
      width: 90%;
    }
    @include mobile(1024px, 1279px) {
      width: 85%;
    }

    .specialisti-block-title {
      flex-basis: 100%;
      width: 60%;
      margin-bottom: 2.39em;

      @include mobile(320px, 767px) {
        margin-bottom: 1.1em;
      }
      @include mobile(768px, 1023px) {
        margin-bottom: 1.95em;
      }

      h3 {
        font-size: 1.78rem;
        line-height: 2.2rem;
        font-weight: bold;
        color: white;

        @include mobile(320px, 767px) {
          font-size: 1.5rem;
        }
      }
    }

    .edit-container {
      .result-grid {
        .card-container {
          column-gap: 1em;

          .col-lg-4 {
            flex: 0 0 31.33333%;
            max-width: 31.33333%;
            min-width: fit-content;
          }
        }
      }
    }

    .result-grid {
      .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        row-gap: 1.1em;

        @include mobile(320px, 767px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .col-sm-12 {
          @include mobile(600px, 767px) {
            flex: 0 0 50%;
          }
        }

        .card-wrapper {
          .card {
            background-color: white;
            border: 1px solid #eceff1;
            border-radius: 4px;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
            padding: 1.89em 1.39em 0em;
            height: fit-content;
            width: 21rem;
            height: 13rem;

            @include mobile(320px, 767px) {
              padding: 1.1em;
              height: fit-content;
            }

            @include mobile(768px, 1023px) {
              padding: 1.67em;
            }

            .card-body {
              padding: 0px;
              height: 60%;
              margin-bottom: 1.1em;

              h3.card-title {
                font-size: 1.3rem;
                color: $primary-text-color;
                display: flex;
                align-items: center;

                @include mobile(320px, 767px) {
                  font-size: 1.1rem;
                }
                @include mobile(768px, 1023px) {
                  font-size: 1.4rem;
                }

                .title-icon-section {
                  svg.icon.fa-icon.icon {
                    fill: $primary-text-color;
                    font-size: 1rem;
                    margin-right: 0.95em;

                    @include mobile(320px, 767px) {
                      font-size: 0.83rem;
                      margin-right: 0.6em;
                    }
                    @include mobile(768px, 1023px) {
                      font-size: 0.95rem;
                      margin-right: 0.6em;
                    }
                  }
                }
              }

              .card-text {
                font-family: 'Lora';
                font-size: 0.8rem;
                line-height: 1.2rem;
                margin-bottom: 2.78em;

                @include mobile(320px, 767px) {
                  font-size: 15px;
                  line-height: 20px;
                  min-height: fit-content;
                  margin-bottom: 0em;
                }
                @include mobile(768px, 1023px) {
                  font-size: 0.83rem;
                  line-height: 1.2rem;
                }
                a {
                  color: $secondary;
                }
                .card-text-reparto {
                  color: $primary-text-color;
                }

                .card-text-dipartimento {
                  color: $body-text-color;
                }
              }
            }
            .read-more {
              font-family: 'Titillium Web';
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: bold;
              letter-spacing: 1.29px;
              text-decoration: none;
              color: $secondary;
              text-transform: uppercase;

              @include mobile(320px, 767px) {
                font-size: 0.83rem;
              }
              @include mobile(768px, 1023px) {
                font-size: 1rem;
              }

              svg {
                margin-left: 0.39em;
                margin-bottom: -0.2em;
                height: 1rem;
                width: 1rem;

                @include mobile(320px, 767px) {
                  margin-bottom: 0.1em;
                }
                @include mobile(768px, 1023px) {
                  margin-bottom: 0em;
                }
              }
            }
          }
        }
      }
    }
    &.edit-container {
      width: 70%;
    }
  }
}
