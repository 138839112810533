.contenttype-biblioteca {
  form.search-form {
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 100%;
    font-size: 1rem;

    @include mobile(320px, 767px) {
      flex-wrap: wrap;
      justify-content: center;
      font-size: 1.1rem;
    }

    .search-box-container {
      flex-basis: 50%;
      display: flex;
      align-items: center;

      @include mobile(320px, 411px) {
        flex-basis: 100%;
      }
      @include mobile(412px, 767px) {
        flex-basis: 80%;
      }

      input[type='text'] {
        height: 3.5rem;
      }

      .filter-wrapper.text-filter {
        flex-basis: 100%;

        input {
          border-radius: 4px;
        }
      }
    }

    .filters-line {
      display: flex;
      flex-wrap: nowrap;
      flex-basis: 50%;
      align-items: center;

      @include mobile(320px, 767px) {
        flex-wrap: wrap;
        flex-basis: 100%;
        justify-content: center;
      }

      .filter-wrapper.select-filter {
        flex-basis: 30%;

        @include mobile(320px, 411px) {
          flex-basis: 100%;
        }

        .bootstrap-select-wrapper {
          div {
            .react-select__control.css-yk16xz-control,
            .react-select__control--is-focused,
            .react-select__control--menu-is-open {
              background-color: transparent;
              border-bottom: none !important;
              display: flex;
              flex-wrap: nowrap;
              width: 100%;

              .react-select__value-container {
                overflow: visible;
                .react-select__placeholder {
                  color: $white !important;
                }

                .css-1uccc91-singleValue {
                  color: $white !important;
                }
              }

              .react-select__indicators.css-1hb7zxy-IndicatorsContainer {
                .react-select__indicator.react-select__dropdown-indicator.css-tlfecz-indicatorContainer {
                  svg {
                    fill: $white !important;
                  }
                }
                .select-pill.text-primary.clearButton {
                  div {
                    svg {
                      fill: $white !important;
                    }
                  }
                }
              }
            }

            &.react-select__control--is-focused {
              .css-g1d714-ValueContainer {
                overflow: visible;
              }
            }

            .css-1pahdxg-control {
              display: flex;
              flex-wrap: nowrap;
              width: 100%;
            }
          }
        }
      }

      button {
        flex-basis: 30%;
        height: 2.22rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile(320px, 767px) {
          margin-top: 1.3em;
          height: 3.5rem;
          font-size: 1.1rem;
        }
      }
    }
  }

  .container .rounded.container.file-manager-container {
    background-color: #e6e6e8;
    display: flex;

    @include mobile(320px, 411px) {
      flex-wrap: wrap;
      justify-content: left;
    }

    .d-flex,
    .d-flex .add-file-container {
      width: 100%;
      justify-content: center;

      @include mobile(320px, 411px) {
        justify-content: left !important;
        flex-wrap: wrap;
      }

      @include mobile(412px, 1023px) {
        flex-wrap: nowrap;
      }

      .add-file-item {
        padding: 1.39em 2.83em;
        display: flex;
        align-items: center;

        @include mobile(412px, 1023px) {
          flex-direction: column;
          padding: 1.39em 0.8em;
        }

        .icon-container {
          margin-right: 1em;

          @include mobile(425px, 1023px) {
            margin-right: 0em;
            margin-bottom: 0.5em;
          }
          svg {
            height: 2.2rem;
            width: auto;
            fill: $primary;

            @include mobile(320px, 767px) {
              height: 3rem;
            }
          }
        }

        .link-container {
          font-size: 1rem;
          line-height: 1.167rem;
          font-weight: 700;

          @include mobile(320px, 767px) {
            font-size: 1.1rem;
            line-height: 1.6rem;
          }
          @include mobile(412px, 1023px) {
            text-align: center;
          }
        }
      }

      &.add-file-container {
        display: flex;
        justify-content: center;
        width: auto;
        align-items: center;
      }
    }
  }

  .navbar-section {
    margin-top: 3.3em;
    margin-bottom: 2.78em;

    .testi-navbar-container {
      margin-top: 2.1em;

      @include mobile(320px, 1023px) {
        overflow-x: scroll;
      }

      .navbar-container {
        display: flex;
        justify-content: space-around;
        background-color: $white;
        border-bottom: 1px solid #e5e5e5;

        @include mobile(320px, 767px) {
          width: 220%;
        }
        @include mobile(768px, 1023px) {
          width: 150%;
        }

        .navbar-button-container {
          width: 100%;
          .navbar-tab {
            background-color: $white;
            border: none;
            font-size: 1.1rem;
            line-height: 2rem;
            font-weight: 600;
            width: 100%;
            padding: 0em 0em 0.1em;

            @include mobile(320px, 767px) {
              font-size: 1.7rem;
            }

            .letter-container {
              padding: 0.3em 0em;
            }
          }

          .navbar-tab:hover,
          .navbar-item-active {
            background-color: rgba(0, 57, 97, 0.07);
            cursor: pointer;
            border-bottom: 2px solid $secondary;
            color: $secondary;
            padding-bottom: 0em;
          }

          button:focus {
            outline: none;
          }
        }
      }
    }
  }

  .result-table-section {
    .result-table-container {
      @include mobile(320px, 1023px) {
        overflow-x: scroll;
      }
      div.table {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        font-size: 1rem;

        @include mobile(320px, 429px) {
          width: 300%;
          overflow-x: scroll;
          font-size: 1.1rem;
        }
        @include mobile(430px, 767px) {
          width: 250%;
          overflow-x: scroll;
          font-size: 1.1rem;
        }
        @include mobile(768px, 1023px) {
          width: 180%;
          overflow-x: scroll;
        }

        div.row.head-row {
          border-bottom: 2px solid #d6dce3;

          div.column {
            p {
              @include rem-size(font-size, 21);
              @include rem-size(line-height, 24);
              font-weight: 600;
            }
          }
        }

        div.row {
          border-bottom: 1px solid #d6dce3;
          margin-right: 0em !important;
          margin-left: 0em !important;
          div.column {
            svg {
              height: 1em;
              width: auto;
              fill: $secondary;
              margin: 0em 0.56em;
            }

            p.sottotitolo {
              font-size: 0.8rem;
              color: lighten($color: $body-text-color, $amount: 40%);

              @include mobile(320px, 767px) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .import-excel-modal {
      .modal-content {
        .modal-header {
          h5 {
            @include mobile(320px, 767px) {
              font-size: 1.1rem;
            }
          }
        }

        .modal-body {
          .excel-modal-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 1.1em 0em;

            div.file-loader,
            div.info-container {
              flex-basis: 100%;
              display: flex;
              justify-content: center;
              label.button {
                width: 15rem;
                height: 2.78rem;
                border-radius: 4px;
                background-color: $primary;
                color: $white;
                align-items: center;
                justify-content: center;
                text-align: center;

                @include mobile(320px, 767px) {
                  width: 12rem;
                  height: 5.7rem;
                  font-size: 1.1rem;
                }
              }

              p {
                font-size: 1.3rem;
                color: $body-text-color;
              }
            }
          }
        }

        .modal-footer {
          .btn {
            @include mobile(320px, 767px) {
              height: 4.2rem;
              font-size: 1.1rem;
              line-height: 1.4rem;
            }
          }
        }
      }
    }
  }
}
