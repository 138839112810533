.contenttype-rubrica {
  .container .rounded.container.file-manager-container {
    background-color: #e6e6e8;
    display: flex;

    @include mobile(320px, 411px) {
      flex-wrap: wrap;
      justify-content: left;
    }

    .d-flex,
    .d-flex .add-file-container {
      width: 100%;
      justify-content: center;

      @include mobile(320px, 411px) {
        justify-content: left !important;
        flex-wrap: wrap;
      }

      @include mobile(412px, 1023px) {
        flex-wrap: nowrap;
      }

      .add-file-item {
        padding: 1.39em 2.83em;
        display: flex;
        align-items: center;

        @include mobile(412px, 1023px) {
          flex-direction: column;
          padding: 1.39em 0.8em;
        }

        .icon-container {
          margin-right: 0.72em;

          @include mobile(320px, 767px) {
            margin-bottom: 0.5em;
          }

          @include mobile(768px, 1023px) {
            margin-right: 0em;
          }
          svg {
            height: 2.2rem;
            width: auto;
            fill: $primary;

            @include mobile(320px, 767px) {
              height: 2.5rem;
            }
          }
        }

        .link-container {
          font-size: 0.8rem;
          line-height: 1.167rem;
          font-weight: 700;

          @include mobile(320px, 767px) {
            font-size: 1.1rem;
            line-height: 1.6rem;
          }

          @include mobile(412px, 1023px) {
            text-align: center;
          }
        }
      }

      &.add-file-container {
        display: flex;
        justify-content: center;
        width: auto;
        align-items: center;
      }
    }
  }

  .result-table-section {
    .alert {
      margin-top: 25px;
    }
    .result-table-container {
      @include mobile(320px, 1023px) {
        overflow-x: scroll;
      }
      div.table {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        font-size: 1rem;

        @include mobile(320px, 429px) {
          width: 500%;
          overflow-x: scroll;
          font-size: 1.1rem;
        }
        @include mobile(430px, 767px) {
          width: 250%;
          overflow-x: scroll;
          font-size: 1.1rem;
        }
        @include mobile(768px, 1023px) {
          width: 180%;
          overflow-x: scroll;
        }

        div.row.head-row {
          border-bottom: 2px solid #d6dce3;
          align-items: center;

          div.column {
            p {
              @include rem-size(font-size, 21);
              @include rem-size(line-height, 24);
              font-weight: 600;
              font-size: 0.85rem;

              @include mobile(320px, 767px) {
                font-size: 1.1rem;
              }
            }
          }
        }

        div.row {
          border-bottom: 1px solid #d6dce3;
          margin-right: 0em !important;
          margin-left: 0em !important;
          font-size: 0.85rem;
          @include mobile(320px, 767px) {
            font-size: 1.1rem;
          }

          div.column {
            svg {
              height: 0.95em;
              width: auto;
              fill: $secondary;
              margin: 0em 0.56em;
            }

            p.sottotitolo {
              font-size: 0.8rem;
              color: lighten($color: $body-text-color, $amount: 40%);

              @include mobile(320px, 767px) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .import-excel-modal {
      .modal-content {
        .modal-header {
          h5 {
            @include mobile(320px, 767px) {
              font-size: 1.1rem;
            }
          }
        }

        .modal-body {
          .excel-modal-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 1.1em 0em;

            div.file-loader,
            div.info-container {
              flex-basis: 100%;
              display: flex;
              justify-content: center;
              label.button {
                width: 15rem;
                height: 2.78rem;
                border-radius: 4px;
                background-color: $primary;
                color: $white;
                align-items: center;
                justify-content: center;
                text-align: center;

                @include mobile(320px, 767px) {
                  width: 12rem;
                  height: 5.7rem;
                  font-size: 1.1rem;
                }
              }

              p {
                font-size: 1.3rem;
                color: $body-text-color;
              }
            }
          }
        }

        .modal-footer {
          .btn {
            @include mobile(320px, 767px) {
              height: 4.2rem;
              font-size: 1.3rem;
              line-height: 1.4rem;
            }
          }
        }
      }
    }
  }
}
