.pagination-section {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile(320px, 767px) {
    flex-wrap: wrap;
    margin-top: 1.1em;
  }
  @include mobile(768px, 1023px) {
    margin-top: 1.1em;
  }

  .pagination-details {
    font-size: 0.8rem;
    line-height: 1.3rem;
    font-weight: 600;
    color: lighten($color: $body-text-color, $amount: 30%);
    margin-right: 0.8em;

    @include mobile(320px, 767px) {
      margin-right: 0em;
      flex-basis: 100%;
      text-align: center;
      font-size: 1.2rem;
    }
  }

  .pagination-nav {
    @include mobile(320px, 411px) {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
    .pagination-wrapper {
      justify-content: flex-start;

      a {
        text-decoration: none;
      }
      .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 4px;
        @include mobile(320px, 411px) {
          flex-wrap: nowrap;
        }
        .page-item {
          margin-right: 0.28em;
          @include mobile(320px, 767px) {
            margin-right: -0.2em;
          }

          .page-link {
            height: 2.66667rem;
            width: 2.66667rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: none;
            font-size: 0.88889rem;
            font-weight: 600;
            background-color: transparent;
            padding: 0.5em 0.75em;

            @include mobile(320px, 767px) {
              height: 2.3rem;
              width: 2rem;
              font-size: 1.2rem;
              padding: 0.2em 0.3em;
              min-width: 2rem;
            }
          }
          .active-arrow-link {
            color: $secondary;
          }

          .inactive-arrow-link {
            color: lighten($color: $body-text-color, $amount: 40%);
            cursor: auto;
          }
        }

        .page-item-active {
          border: 1px solid $secondary;
          border-radius: 4px;

          .page-link-active {
            color: $secondary;
          }
        }

        .large-pagination {
          display: flex;
          align-items: center;

          .more-pages-indicator {
            margin-right: 0.28em;
            color: lighten($color: $body-text-color, $amount: 40%);
          }
        }

        .skip-to {
          height: 25px;
          width: auto;

          @include mobile(320px, 767px) {
            height: 1.1rem;
          }
        }
      }
    }
  }
}
