.result-card-container {
  margin-bottom: 1.95em;
  .card-container.row {
    row-gap: 1.67em;

    .single-card-container {
      flex: 1 0 29.3333%;

      @include mobile(320px, 575px) {
        flex: auto;
      }

      @include mobile(576px, 767px) {
        flex: 1 0 47%;
      }

      @include mobile(768px, 1023px) {
        flex: 1 0 47%;
      }

      .card {
        height: fit-content;
        border: 1px solid #eceff1;
        border-radius: 4px;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
        padding: 1.67em 1.4em 0.95em;
        height: 16.1rem;

        @include mobile(320px, 1280px) {
          height: fit-content;
        }

        @include mobile(576px, 767px) {
          margin: auto;
          height: fit-content;
        }
        @include mobile(768px, 1023px) {
          margin: auto;
          height: 16.1rem;
        }

        h4 {
          font-size: 24px;
          line-height: 28px;
          color: $black;

          @include mobile(768px, 1023px) {
            font-size: 1.16rem;
            line-height: 1.3rem;
          }
        }

        p {
          font-family: 'Lora';
          font-size: 0.8rem;
          line-height: 1.3rem;
          color: $black;
          margin-top: 1.3rem;
          padding-top: 0em;
          margin-bottom: 0em;
          @include mobile(768px, 1023px) {
            font-size: 0.83rem;
            line-height: 1.22rem;
            margin-top: 1em;
          }
        }

        a {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1rem;
          font-weight: bold;
          @include rem-size(line-height, 27);
          letter-spacing: 1.29px;
          color: $secondary;
          @include margin-size(margin-top, 25);

          @include mobile(768px, 1023px) {
            @include rem-size(line-height, 22);
            @include margin-size(margin-top, 15);
          }
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
